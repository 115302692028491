<template>
  <div class="company-news">
    <div class="company-news__container _container">
      <BreadCrumbs
        :items="[
          [$t('main'), '/'],
          [$t('news'), '/news'],
          [selectedNews.title],
        ]"
      />
      <h1 class="company-news__title main-title">{{ $t("news") }}</h1>
      <div class="company-news__body">
        <div class="company-news__left">
          <h2 class="company-news__name second-title">
            {{ selectedNews.title }}
          </h2>
          <div class="company-news__additional">
            <div class="company-news__date">
              <span
                ><img src="../assets/icons/news/calendar.svg" alt=""
              /></span>
              {{ longFormatDate(selectedNews.created_at) }}
            </div>
            <div class="company-news__views">
              <span><img src="../assets/icons/news/eye.svg" alt="" /></span>
              {{ selectedNews.shows }}
            </div>
          </div>
          <div
            class="company-news__text"
            v-html="selectedNews.description"
          ></div>
        </div>

        <div class="company-news__right">
          <h2 class="company-news__second-title second-title">
            {{ $t("allNews") }}
          </h2>
          <div class="company-news__items">
            <div
              class="company-news__item news-item"
              v-for="(newsItem, id) in news"
              :key="id"
            >
              <router-link
                :to="{ name: 'News', params: { id: newsItem.id } }"
                class="news-item__wrapper"
              >
                <div class="news-item__image">
                  <img :src="cdn + newsItem.image" alt="" />
                </div>
                <div class="news-item__content">
                  <div class="news-item__name">{{ newsItem.title }}</div>
                  <div class="news-item__additional">
                    <div class="news-item__date">
                      <span
                        ><img src="../assets/icons/news/calendar.svg" alt=""
                      /></span>
                      {{ shortFormatDate(newsItem.created_at) }}
                    </div>
                    <div class="news-item__views">
                      <span
                        ><img src="../assets/icons/news/eye.svg" alt=""
                      /></span>
                      {{ newsItem.shows }}
                    </div>
                  </div>
                  <div
                    class="news-item__text"
                    v-html="newsItem.description"
                  ></div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";

export default {
  data() {
    return {
      selectedNews: {},
      news: [],
    };
  },
  created() {
    this.getSelectedNews();
    this.getNews();
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    getSelectedNews() {
      axios
        .get(`V1/news-by-id`, {
          params: {
            news_id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.selectedNews = response.data.data;
        })
        .catch((error) => {
        });
    },
    getNews() {
      axios
        .get(`V1/news`)
        .then((response) => {
          this.news = response.data.data;
        })
        .catch((error) => {
        });
    },
    longFormatDate(date) {
      var currentLang = localStorage.getItem("lang") || "ru";
      var smallLang = "ru-RU";
      if (currentLang === "kz") {
        smallLang = "kk-KZ";
      } else if (currentLang === "en") {
        smallLang = "en-EN";
      } else {
        smallLang = "ru-RU";
      }
      return new Date(date).toLocaleDateString(smallLang, {
        year: "numeric",
        day: "numeric",
        month: "long",
      });
    },
    shortFormatDate(date) {
      return new Date(date).toLocaleDateString("en-GB", {
        year: "numeric",
        day: "numeric",
        month: "numeric",
      });
    },
  },
  watch: {
    "$route.params.id": function () {
      this.getSelectedNews();
    },
  },
  components: { BreadCrumbs },
};
</script>

<style lang="scss">
.company-news {
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }

  div,
  p,
  a,
  span {
    text-align: justify;
  }
  padding: 50px 0 94px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 127px 0;
  }

  &__title {
    margin: 0 0 38px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 20px 0;
    }
  }

  &__second-title {
    margin: 0 0 22px 0;
  }

  &__body {
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0 29px;
    }
  }

  &__left {
    flex: 0 1 auto;
    @media (max-width: $tablet + px) {
      margin: 0 0 108px 0;
    }
  }
  &__right {
    flex: 0 0 515px;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    padding: 32px 10px 40px 20px;
  }

  &__items {
    height: 520px;
    overflow-y: scroll;
    padding-right: 10px;
  }

  &__name {
    margin: 0 0 27px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 32px 0;
    }
  }

  &__additional {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 27px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 32px 0;
    }
  }

  &__date,
  &__views {
    display: flex;
    align-items: center;
    gap: 0 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 136.69%;
    color: #646464;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
  }

  &__date {
    span {
      img {
        width: 20px;
        height: 22px;
      }
    }
  }

  &__views {
    span {
      img {
        width: 100%;
        height: 75%;
      }
    }
  }

  &__text {
    @extend .base-text;
    color: #000000;
    font-weight: 400;

    p {
      &:not(:last-child) {
        margin: 0 0 22px 0;
      }
    }
  }
}

.news-item {
  border-radius: 10px;
  overflow: hidden !important;
  border: 1px solid #c2c2c2;
  &:not(:last-child) {
    margin: 0 0 15px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 20px 0;
    }
  }

  &__wrapper {
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: center;
    }
  }

  &__image {
    flex: 0 0 172px;
    img {
      width: 100%;
      min-height: 185px;
      object-fit: cover;

      @media screen and (max-width: 1024px) {
        height: 100%;
      }
    }
  }

  &__content {
    padding: 5px 20px 11px 15px;
    background: #ffffff;
    @media (max-width: $tablet + px) {
      padding: 15px 20px 15px 10px;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2e3236;
    margin: 0 0 12px 0;
    transition: color 0.3s ease;
    &:hover {
      color: #1ABBE8;
    }
  }

  &__additional {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 12px 0;
  }

  &__date,
  &__views {
    display: flex;
    align-items: center;
    gap: 0 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 136.69%;
    color: #646464;

    span {
      width: 14px;
      height: 14px;
    }
  }

  &__date {
    img {
      width: 11.67px;
      height: 12.83px;
    }
  }

  &__views {
    img {
      width: 100%;
      height: 85%;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 136.69%;
    color: #bebebe;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
    max-height: 65.25px;
    height: 65.25px;
  }
}
</style>
